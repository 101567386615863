import { render, staticRenderFns } from "./Sao-Sebastiao-do-Cai.vue?vue&type=template&id=b0be2bae&scoped=true&"
import script from "./Sao-Sebastiao-do-Cai.vue?vue&type=script&lang=js&"
export * from "./Sao-Sebastiao-do-Cai.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0be2bae",
  null
  
)

export default component.exports